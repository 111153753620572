<template>
  <div class="absolute inset-0 flex items-center justify-center" v-if="!guest.id">
    <loading-icon class="h-10 w-10 text-pink-500" />
  </div>

  <div v-else class="relative">
    <div v-show="working" class="loading-container" data-html2canvas-ignore>
      <loading-icon class="h-10 w-10 text-pink-500" />
    </div>

    <div ref="canvas" class="relative">
      <div class="px-4 lg:px-0 pb-10 lg:pb-20">
        <router-link v-if="showEditButton" :to="{ name: 'GuestDetails' }" class="edit-profile" :style="backgroundColor"
          :class="{ gradient: guest.color == null }" data-html2canvas-ignore>
          Edit profile
        </router-link>
        <div ref="coverImage" class="h-72 absolute inset-x-0 top-0" :style="coverImage">
          <div v-if="!guest.coverImageUrl" class="absolute top-0 z-10 w-1/2 h-64 m-4 bg-cover bg-no-repeat"
            :style="noCoverImage" data-html2canvas-ignore></div>
        </div>

        <div class="container main-container">
          <!-- Left col-->
          <div class="w-full lg:w-4/12 xl:w-3/12 lg:mr-20 mb-10 lg:mb-0">
            <div class="lg:bg-white lg:shadow-xl rounded-xl mb-10">
              <!-- Image-->
              <div v-if="!isVideo" :style="'background-image: url(' + guestImage + ')'" class="image-container">
                <button class="fav-btn top-10">
                  <FavoriteButton :isFavorited="guest.is_favourited" @toggleFavorite="favorite" size="small"
                    :loading="loadingToggle" v-bind="guestColorProp('color')" data-html2canvas-ignore />
                </button>
              </div>
              <!-- Video-->
              <div v-else class="video-container">
                <video :src="guestImage" loop="loop" muted="true" autoplay="true"
                  class="object-cover h-full rounded-t-lg"></video>
                <button class="rounded-full bg-white absolute top-0 right-0 mt-5 mr-5">
                  <FavoriteButton :isFavorited="guest.is_favourited" size="small" :loading="loadingToggle"
                    v-bind="guestColorProp('color')" @toggleFavorite="favorite" />
                </button>
              </div>

              <!-- General info - ONLY ON MOBILE: -->
              <MobileGeneralInfo :profile="guest" :profile-reach="profileReach"
                class="flex flex-col items-center my-6 relative lg:hidden" />

              <template v-if="guest.inquiries_only">
                <div class="flex justify-center -mt-3 relative z-10 mb-4">
                  <InquireButton :profile="guest" profileType="guest" data-html2canvas-ignore />
                </div>
              </template>
              <template v-else>
                <div class="flex justify-center -mt-3 relative z-10 mb-4" ref="bookNowButtonRef"
                  @mouseenter="toggleBookingTooltip('Book Now', 'bookNowButtonRef')"
                  @mouseleave="toggleBookingTooltip()">
                  <BookNowButton :profile="guest" data-html2canvas-ignore />
                </div>
                <div class="flex justify-center" ref="pitchButtonRef"
                  @mouseenter="toggleBookingTooltip('Pitch', 'pitchButtonRef')" @mouseleave="toggleBookingTooltip()">
                  <PitchButton :profile="guest" border="border border-violet" type="guest" data-html2canvas-ignore />
                </div>
              </template>
              <div ref="bookingTooltipRef" :class="{ 'hidden': !bookingTooltipShow, 'block': bookingTooltipShow }"
                class="block z-50 mt-2 max-w-sm w-full text-left no-underline break-words rounded-full">
                <div class="bg-indigo-500 text-white text-xs py-2 px-3 mb-0 rounded-lg absolute">
                  <h2>{{ bookingOptionTooltip }}</h2>
                </div>
              </div>
              <!--Tags-->
              <div ref="tags" class="p-4">
                <Tags :tags="guest.tags" type="guests" />
              </div>

              <!--Experience-->
              <Accordion v-if="guest.experience.length !== 0" :list="guest.experience" :convert="convert">
                <template v-slot:title>
                  <h4 class="font-bold text-blue-800 text-sm">
                    I have
                    <span v-if="!convert" class="bg-clip-text text-transparent" :style="backgroundColor"
                      :class="{ gradient: guest.color == null }">Experience</span>
                    <span v-else :style="'color: ' + (guest.color ? guest.color : '#9e2bab')
                      ">Experience</span>
                    in
                  </h4>
                </template>
              </Accordion>

              <!--Questions-->
              <Accordion v-if="guest.questions.length !== 0" :list="guest.questions" :convert="convert">
                <template v-slot:title>
                  <h4 class="font-bold text-blue-800 text-sm">
                    <span v-if="!convert" class="bg-clip-text text-transparent" :style="backgroundColor"
                      :class="{ gradient: guest.color == null }">Questions</span>
                    <span v-else :style="'color: ' + (guest.color ? guest.color : '#9e2bab')
                      ">Questions</span>
                    to ask
                  </h4>
                </template>
              </Accordion>

              <!--Topics-->
              <Accordion :list="guest.topics" v-if="guest.topics.length !== 0" :convert="convert">
                <template v-slot:title>
                  <h4 class="font-bold text-blue-800 text-sm">
                    <span v-if="!convert" class="bg-clip-text text-transparent" :style="backgroundColor"
                      :class="{ gradient: guest.color == null }">Topics</span>
                    <span v-else :style="'color: ' + (guest.color ? guest.color : '#9e2bab')
                      ">Topics</span>
                    to discuss
                  </h4>
                </template>
              </Accordion>
            </div>

            <!--Social links-->
            <div class="flex justify-around md:justify-start" data-html2canvas-ignore>
              <SocialLinks :socialLinks="guest.links.social_media" />
            </div>

            <MediaKit v-if="guest.media_kit_url" :url="guest.media_kit_url" />

            <!--Reviews-->
            <Reviews :ratings="guest.ratings" :avgRating="guest.avg_rating" type="guest" :userId="guest.user_id"
              :profileId="guest.id" :profileName="guest.name" :profileImg="guest.imageUrl"
              v-bind="guestColorProp('starColor')" data-html2canvas-ignore />
          </div>
          <!-- Right col-->
          <div class="w-full lg:w-8/12 xl:w-9/12 relative">
            <div class="relative hidden lg:block h-72 py-10">
              <div class="inline-flex items-center">
                <span class="guest-name">{{ guest.name }}</span>
                <span v-if="guest.pronunciation" class="ml-4">
                  <AudioPreviewSlim :media-src="guest.pronunciation" class="audio-preview-slim"
                    border-color="border-white" />
                </span>
              </div>
              <span v-if="guest.is_pro" class="is-pro" :style="`background-color: ${guest.color ? guest.color : '#B503D0'
                }`">Pro</span>
              <div class="grid grid-cols-2">
                <div class="flex">
                  <div class="flex flex-col">
                    <h2 class="capitalize lg:text-2xl leading-8 text-white" style="min-height: 32px">
                      {{ guest.title }}
                    </h2>
                    <div class="flex items-start flex-col justify-end" style="min-height: 70px">
                      <div>
                        <span v-for="(category, index) in guest.categories" :key="index"
                          class="text-white text-lg uppercase font-bold">
                          <span v-if="index > 0"> - </span>
                          <router-link :to="{
                            name: 'Browse',
                            query: {
                              category: category.id,
                              showing: 'guests',
                            },
                          }" class="hover:text-violet">{{ category.name }}</router-link>
                        </span>
                      </div>
                      <Rating margin-bottom="mb-0" :ratings="guest.ratings" :avgRating="guest.avg_rating"
                        v-bind="guestColorProp('color')" data-html2canvas-ignore />
                      <div v-if="profileReach.length" class="reach-container">
                        <template v-for="(reach, index) in profileReach">
                          <div :key="`reach-${index}`" class="reach-item">
                            <component :is="`${reach.platform}-icon`" :class="{
                              'h-6 pl-3': reach.platform == 'twitter',
                              'h-8 pl-1': reach.platform != 'twitter',
                            }" />
                            <span class="ml-1 whitespace-nowrap uppercase"
                              :class="{ 'ml-2': reach.platform == 'twitter' }">
                              {{ reach.from }} - {{ reach.to }}
                            </span>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex justify-end" data-html2canvas-ignore>
                  <div class="flex flex-col justify-between">
                    <ShareProfileButton :profileUrl="profileUrl" />
                    <ViewShowProfilesButton v-if="guest.shows && guest.shows.length > 0" :profiles="guest.shows"
                      :profileName="guest.name" oppositeType="show" v-bind="guestColorProp('backgroundColor')"
                      class="mt-2" />
                    <SaveAsPdfButton @downloadPdf="prepareDoc" class="mt-2" :class="{ 'opacity-50': working }"
                      :disabled="working" v-bind="guestColorProp('backgroundColor')" />
                  </div>
                </div>
              </div>
            </div>

            <!--Guest Booking Options-->
            <div v-if="availableBookingOptions.length" data-html2canvas-ignore class="mb-12">
              <h4 class="guest-title">Booking Options</h4>
              <div class="text-sm flex flex-wrap relative">
                <button v-for="(option, index) in availableBookingOptions" :key="`option-${index}`" type="button"
                  class="booking-option-btn" style="box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.07)"
                  @mouseenter="toggleTooltip(option.slug, option.description)" @mouseleave="toggleTooltip()"
                  @click="goToGuestBookingOptions(option.id)">
                  <div class="flex justify-center h-10 flex-shrink-0">
                    <img :src="option.icon_url" class="h-10 w-10" />
                  </div>

                  <div class="booking-option-name">
                    {{ option.name }}
                  </div>
                </button>
              </div>

              <div ref="tooltipRef" :class="{ hidden: !tooltipShow, block: tooltipShow }"
                class="max-w-xs text-left absolute">
                <div class="tooltip-inner">
                  <h2>{{ bookingDescription }}</h2>
                </div>
              </div>
            </div>

            <!--About and description-->
            <div data-html2canvas-ignore>
              <h4 class="guest-title">About {{ guest.name }}</h4>
              <div v-html="guest.bio" class="guest-bio" />
            </div>
            <!-- Links-->
            <Links :links="guest.links" :photos="guest.photos" data-html2canvas-ignore
              v-bind="guestColorProp('guestColor')" />
            <div class="grid grid-cols-3 gap-2 fixed top-0 left-0" ref="photos" :style="'visibility: hidden'">
              <div class="w-48 h-48 pt-6" v-for="(pic, index) in photoUrls" :key="index">
                <div class="h-full w-full overflow-hidden items-center" :style="getPicStyle(index, pic)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--How guestio works-->
      <HowGuestioWorks data-html2canvas-ignore />
    </div>
  </div>
</template>

<script>
import { get, chain } from 'lodash';
import PitchButton from '@/components/profile/PitchButton';
import BookNowButton from '@/components/profile/BookNowButton';
import InquireButton from '@/components/profile/InquireButton';
import Rating from '@/components/profile/Rating';
import Tags from '@/components/profile/Tags';
import Links from '@/components/profile/Links';
import HowGuestioWorks from '@/components/shared/HowGuestioWorks';
import Accordion from '@/components/profile/Accordion';
import FavoriteButton from '@/components/profile/FavoriteButton';
import ShareProfileButton from '@/components/profile/ShareProfileButton';
import ViewShowProfilesButton from '@/components/profile/ViewShowProfilesButton';
import SaveAsPdfButton from '@/components/profile/SaveAsPdfButton';
import Reviews from '@/components/profile/Reviews/index';
import MediaKit from '@/components/profile/MediaKit';
import MobileGeneralInfo from '@/components/profile/mobile/MobileGeneralInfo';
import SocialLinks from '@/components/profile/SocialLinks';
import TwitterIcon from '@/components/svgs/ReachIcons/TwitterIcon';
import TiktokIcon from '@/components/svgs/ReachIcons/TiktokIcon';
import YoutubeIcon from '@/components/svgs/ReachIcons/YoutubeIcon';
import InstagramIcon from '@/components/svgs/ReachIcons/InstagramIcon';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import AudioPreviewSlim from '@/components/shared/AudioPreviewSlim';
import Popper from 'popper.js';
import { mapMutations } from 'vuex';

export default {
  name: 'Guest',

  components: {
    Accordion,
    HowGuestioWorks,
    Tags,
    Rating,
    BookNowButton,
    InquireButton,
    Links,
    FavoriteButton,
    Reviews,
    MobileGeneralInfo,
    SocialLinks,
    ShareProfileButton,
    ViewShowProfilesButton,
    TwitterIcon,
    TiktokIcon,
    YoutubeIcon,
    InstagramIcon,
    AudioPreviewSlim,
    SaveAsPdfButton,
    MediaKit,
    PitchButton
  },

  data() {
    return {
      loadingToggle: false,
      profileReach: [],
      working: false,
      convert: false,
      tooltipShow: false,
      bookingTooltipShow: false,
      bookingDescription: '',
      bookingOptionTooltip: '',
    };
  },

  async created() {
    window.Event.$on('loggedIn', this.favorite);

    try {
      let { data } = await this.fetchGuest();

      if (data && data.author && data.author.isPaused) {
        this.$router.push({ name: 'NotFound' });
      }
    } catch (error) {
      if (error.response && error.response.status == 404) {
        this.$router.push({ name: 'NotFound' });
      }
    }

    this.fetchProfileReach();
    this.setPreviousPage('profile');
  },

  destroyed() {
    this.$store.commit('guest/clearGuest');
    window.Event.$off('loggedIn');
  },

  methods: {
    ...mapMutations({
      setPreviousPage: "dashboard/bookings/setPreviousPage",
    }),

    getCorsImageUrl(url) {
      if (!get(this, 'corsProxyUrl', null)) {
        return url;
      }

      return `${this.corsProxyUrl}${url}`;
    },

    getPicStyle(index, pic) {
      return index <= 5
        ? {
          'background-image': `url(${this.getCorsImageUrl(pic)})`,
          'background-position': 'center',
          'background-size': 'cover',
        }
        : ''
    },

    async fetchGuest() {
      return this.$store.dispatch('guest/fetchGuest', {
        id: this.$route.params.guestId,
        includes: 'ratings,links,shows,author,pitch_settings'
      });
    },

    goToGuestBookingOptions(bookingOptionId) {
      this.$router.push({
        name: 'GuestBookingOptions',
        params: { guestId: this.guest.id, bookingOptionId }
      });
    },

    toggleTooltip(name, description) {
      this.bookingDescription = description;
      // switch (name) {
      //   case 'virtual-interview':
      //     this.bookingDescription =
      //       'Whether you host a podcast, youtube channel, or private Q&A for your executive team, use this option to book an interview with your dream guest.';
      //     break;
      //   case 'miniview':
      //     this.bookingDescription =
      //       'Submit up to ten custom questions for this guest to answer. The guest will be able to answer each question with a quick reply.';
      //     break;
      //   case 'live-platform':
      //     this.bookingDescription =
      //       'Clubhouse, Facebook, Youtube, Instagram -- If you go live and you need guests, use this option.';
      //     break;
      //   case 'media-feature':
      //     this.bookingDescription =
      //       'If you are a blogger or contributor to a large publication, and you need experts to quote in your article, use this option for written responses to your questions.';
      //     break;
      //   case 'virtual-event':
      //     this.bookingDescription =
      //       "Virtual stages are here to stay! If you're wanting a guest for a board meeting, virtual conference, private dinner, or something else, this is the option for you.";
      //     break;
      //   case 'pitch':
      //     this.bookingDescription =
      //       'Send a pitch if you think your show would be a great fit for this guest. If they accept your pitch, this fee will replace the usual booking fee.';
      //     break;
      // }
      if (this.tooltipShow) {
        this.tooltipShow = false;
      } else {
        this.tooltipShow = true;
        new Popper(this.$refs.btnRef, this.$refs.tooltipRef, {
          placement: 'right'
        });
      }
    },

    toggleBookingTooltip(name, ref) {
      this.bookingOptionTooltip = '';

      switch (name) {
        case 'Book Now':
          this.bookingOptionTooltip = 'Send your guest request and guest appearance fee up front';
          break;
        case 'Pitch':
          this.bookingOptionTooltip = 'Send your guest request for free and allow the host to respond accordingly';
          break;
      }

      if (this.bookingTooltipShow) {
        this.bookingTooltipShow = false;
      } else {
        this.bookingTooltipShow = true;
        new Popper(this.$refs[`${ref}`], this.$refs.bookingTooltipRef, {
          placement: 'bottom-start'
        });
      }
    },

    openModal() {
      window.Event.$emit('openModal');
    },

    async favorite() {
      this.loadingToggle = true;
      await this.$store.dispatch('dashboard/smartlist/toggleVip', {
        profileId: this.guest.id,
        type: 'guests'
      });
      await this.fetchGuest();
      this.loadingToggle = false;
    },

    guestColorProp(prop) {
      const result = {};

      if (this.guest.color) {
        result[prop] = this.guest.color;
      }

      return result;
    },

    fetchProfileReach() {
      this.$store
        .dispatch('guest/fetchProfileReach', this.guest.id)
        .then(({ data }) => {
          this.profileReach = data.data;
        });
    },

    async savePressKit() {
      window.scrollTo(0, 0);

      const doc = new jsPDF();

      let canvas = await html2canvas(this.$refs.canvas, {
        allowTaint: false,
        useCORS: true
      }).then(canvas => {
        return canvas.toDataURL('image/jpeg', 1);
      });

      const imgProps = doc.getImageProperties(canvas);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      doc.addImage(canvas, 'JPEG', 0, 0, pdfWidth, pdfHeight); // Canvas

      this.$refs.photos.style.visibility = 'visible';

      await this.addDetailsToPdf(doc);

      doc.save('press-kit.pdf'); // Save the PDF

      this.$refs.photos.style.visibility = 'hidden';

      this.convert = false;
      this.working = false;
    },

    async addDetailsToPdf(doc) {
      let xOffset = 73;
      let yOffset = this.initialXOffset;

      if (this.guest.bio && this.guest.bio.length) {
        doc
          .setFontSize(7)
          .setFont(undefined, 'bold')
          .setTextColor(0, 0, 0);

        doc.text(xOffset, yOffset, 'About ' + this.guest.name);

        yOffset += 5;

        doc
          .setFontSize(7)
          .setFont(undefined, 'normal')
          .setTextColor(0, 0, 0);

        let splitBio = doc.splitTextToSize(this.guest.bio, 120);
        doc.text(xOffset, yOffset, splitBio);

        const emptyLines = splitBio.filter(line => line == '').length;
        yOffset += (2 * (splitBio.length + emptyLines)) + emptyLines;

        yOffset += 8;
      }

      if (this.guest.links.podcasts_parsed.length) {
        doc
          .setFontSize(7)
          .setFont(undefined, 'bold')
          .setTextColor(0, 0, 0);

        doc.text(xOffset, yOffset, 'Past podcast appearances');

        yOffset += 5;

        this.guest.links.podcasts_parsed.forEach(podcast => {
          if (podcast.episodeTitle) {
            doc
              .setFontSize(6)
              .setFont(undefined, 'normal')
              .setTextColor(6, 69, 173);
            doc.textWithLink(
              podcast.episodeTitle.substring(0, 45) + '...',
              xOffset,
              yOffset,
              { url: podcast.url }
            ); // Podcasts
            yOffset += 5;
          } else if (podcast.podcastDescription) {
            doc
              .setFontSize(6)
              .setFont(undefined, 'normal')
              .setTextColor(6, 69, 173);
            doc.textWithLink(
              podcast.podcastDescription.substring(0, 45) + '...',
              xOffset,
              yOffset,
              { url: podcast.url }
            ); // Podcasts
            yOffset += 5;
          } else if (podcast.podcastTitle) {
            doc
              .setFontSize(6)
              .setFont(undefined, 'normal')
              .setTextColor(6, 69, 173);
            doc.textWithLink(
              podcast.podcastTitle.substring(0, 45) + '...',
              xOffset,
              yOffset,
              { url: podcast.url }
            ); // Podcasts
            yOffset += 5;
          }
        });
      }

      if (this.guest.photos.length || Object.values(this.guest.photos).length) {
        // Images
        yOffset += 3;

        doc
          .setFontSize(7)
          .setFont(undefined, 'bold')
          .setTextColor(0, 0, 0);

        doc.text(xOffset, yOffset, 'Photos and Headshots');

        yOffset += 5;

        let photosElement = await html2canvas(this.$refs.photos, {
          allowTaint: false,
          useCORS: true
        }).then(photos => {
          return photos.toDataURL('image/jpeg', 1);
        });

        let photosHeight =
          Object.values(this.guest.photos).length <= 3 ? 39 : 78;

        doc.addImage(
          photosElement,
          'JPEG',
          xOffset,
          yOffset,
          120,
          photosHeight
        );

        this.$refs.photos.style.visibility = 'hidden';

        if (Object.values(this.guest.photos).length > 3) {
          yOffset += 70;
        } else {
          yOffset += 35;
        }
      }

      if (this.guest.links.videos.length) {
        yOffset += 7;

        doc
          .setFontSize(7)
          .setFont(undefined, 'bold')
          .setTextColor(0, 0, 0);

        doc.text(xOffset, yOffset, 'Videos');

        yOffset += 5;

        this.guest.links.videos.forEach(videoUrl => {
          if (videoUrl) {
            doc
              .setFontSize(6)
              .setFont(undefined, 'normal')
              .setTextColor(6, 69, 173);
            doc.textWithLink(
              videoUrl.substring(0, 45) + '...',
              xOffset,
              yOffset,
              { url: videoUrl }
            ); // Videos
            yOffset += 5;
          }
        });
      }

      if (this.guest.links.articles_parsed.length) {
        yOffset += 3;

        doc
          .setFontSize(7)
          .setFont(undefined, 'bold')
          .setTextColor(0, 0, 0);

        doc.text(xOffset, yOffset, 'Articles');

        yOffset += 5;

        this.guest.links.articles_parsed.forEach(article => {
          if (article.title) {
            doc
              .setFontSize(6)
              .setFont(undefined, 'normal')
              .setTextColor(6, 69, 173);
            doc.textWithLink(
              article.title.substring(0, 45) + '...',
              xOffset,
              yOffset,
              { url: article.url }
            ); // Articles
            yOffset += 5;
          }
        });

        yOffset += 7;
      }

      return Promise.resolve();
    },

    prepareDoc() {
      this.convert = true;
      this.working = true;

      setTimeout(() => this.savePressKit(), 200);
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser'];
    },

    guest() {
      return this.$store.getters['guest/getGuest'];
    },

    corsProxyUrl() {
      return process.env.VUE_APP_CORS_PROXY_URL;
    },

    guestImage() {
      const defaultImage = require('@/assets/images/test_image.png');
      return this.guest.imageUrl
        ? this.getCorsImageUrl(this.guest.imageUrl)
        : defaultImage;
    },

    isVideo() {
      const videoTypes = ['mp4', 'ogg', 'mov', 'qt'];

      let extension = this.guestImage.split('.').pop();
      return videoTypes.includes(extension);
    },

    coverImage() {
      let background =
        'background: linear-gradient(180deg, rgba(0, 0, 0, 20%) 0%, #000000 100%),';
      if (this.guest.coverImageUrl) {
        background += `url('${this.getCorsImageUrl(
          this.guest.coverImageUrl
        )}'); -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;`;
      } else {
        background = 'background: #161616; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);';
      }
      return background;
    },

    noCoverImage() {
      return `opacity: 0.9; filter: blur(15px); border-radius: 0px; background-position-y: 25%; background: linear-gradient(97.33deg, rgba(22, 22, 22, 0.73) -0.31%, #161616 90.2%), url('${this.guestImage}');`;
    },

    frontendUrl() {
      return process.env.VUE_APP_URL;
    },

    profileUrl() {
      return `${this.frontendUrl}/guests/${this.guest.slug ? this.guest.slug : this.guest.id
        }`;
    },

    showEditButton() {
      return (
        this.user && this.user.guest && this.user.guest.id == this.guest.id
      );
    },

    backgroundColor() {
      return this.guest.color ? `background-color: ${this.guest.color}` : '';
    },

    optionPrices() {
      return get(this.guest, 'option_prices', []);
    },

    allBookingOptions() {
      if (!this.optionPrices.length) {
        return [];
      }

      return chain(this.optionPrices)
        .map(price => {
          return {
            id: price.booking_option.id,
            icon_url: price.booking_option.icon_url,
            name: price.booking_option.name,
            order_column: price.booking_option.order_column,
            price: price.price,
            slug: price.booking_option.slug,
            description: price.booking_option.description
          };
        })
        .uniqBy('id')
        .sortBy('order_column')
        .value();
    },

    availableBookingOptions() {
      return this.allBookingOptions.filter(option => option.slug != 'pitch');
    },

    photoUrls() {
      let allUrls = [];

      for (let i = 0; i < Object.entries(this.guest.photos).length; i++) {
        allUrls.push(Object.entries(this.guest.photos)[i][1]);
      }

      return allUrls;
    },
    windowWidth() {
      return window.innerWidth;
    },
    initialXOffset() {
      if (this.windowWidth > 1600) {
        return 42;
      } else if (this.windowWidth > 1400) {
        return 50;
      } else if (this.windowWidth > 1300) {
        return 55;
      } else if (this.windowWidth > 1200) {
        return 60;
      } else if (this.windowWidth > 1100) {
        return 65;
      }

      return 70;
    }
  }
};
</script>

<style scoped lang="scss">
.edit-profile {
  @apply text-white absolute z-40 right-0 m-5 transition duration-300 ease-in-out border rounded-full px-6 py-2 text-xs font-medium opacity-50;

  &:hover {
    @apply opacity-100;
  }
}

.main-container {
  @apply mx-auto flex flex-wrap lg:flex-no-wrap relative z-10 pt-24 lg:pt-10;
}

@media (max-width: 1390px) {
  .container {
    max-width: 1174px;
  }
}

.gradient {
  @apply bg-gradient-to-r from-indigo-500 to-pink-500;
}

.loading-container {
  @apply fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white flex flex-col items-center justify-center;
}

.image-container {
  @apply w-56 lg:w-full h-64 mx-auto lg:h-72 rounded-xl lg:rounded-b-none bg-center bg-cover bg-no-repeat relative;
}

.fav-btn {
  @apply rounded-full bg-white absolute right-0 mt-5 mr-5;
}

.video-container {
  @apply w-56 lg:w-full h-64 mx-auto my-auto lg:h-72 rounded-xl lg:rounded-b-none bg-center bg-cover bg-no-repeat relative;
}

.guest-name {
  @apply text-3xl lg:text-4xl text-white font-bold capitalize leading-5;
}

.audio-preview-slim {
  @apply text-white hover:opacity-70 transition duration-150 ease-in-out;
}

.is-pro {
  @apply uppercase text-white text-xs rounded-full px-3 py-1 font-semibold absolute top-0 -mt-4 ml-3;
}

.guest-title {
  @apply font-normal text-grayHome capitalize lg:text-2xl leading-8;
}

.reach-container {
  @apply mt-6 col-span-2 flex items-center space-x-8 text-white;
}

.reach-item {
  @apply flex items-center bg-white bg-opacity-10 rounded h-9.5 pr-3 mb-4;
}

.booking-option-btn {
  @apply mr-4 w-30 mb-4 px-4 py-4 bg-white rounded-xl flex flex-col relative items-center;
}

.booking-option-name {
  @apply flex-1 flex items-center justify-center mt-4 font-semibold text-center text-sm;
}

.tooltip-inner {
  @apply bg-indigo-500 text-white text-xs py-2 px-3 rounded-lg;
}

.guest-bio {
  @apply whitespace-pre-wrap leading-6 overflow-hidden mb-12 text-sm xl:w-10/12;
}
</style>
