<template>
  <div>
    <div class="grid grid-cols-1 row-gap-20 mb-10">
      <!-- Podcasts-->
      <div v-if="podcasts.length">
        <h4 class="guest-title">Past podcast appearances</h4>
        <div class="hidden lg:grid grid-cols-1 gap-8">
          <Podcast
            :podcast="podcast" v-for="(podcast, index) in podcasts" :key="index" :fontColor="guestColor"/>
        </div>
      <no-ssr>
        <flickity class="block lg:hidden" ref="flickity" :options="flickityOptions">
          <div v-for="(podcast, index) in podcasts" :key="index" class="carousel-cell">
            <Podcast :podcast="podcast" :fontColor="guestColor"/>
          </div>
        </flickity>
      </no-ssr>
      </div>
      <!-- Galery-->
      <div v-if="!isEmpty(photos)">
        <div class="flex items-center mb-8 justify-between">
          <h4 class="guest-title">Photos and headshots</h4>
          <!-- <a href="#" class="text-pink-500 text-sm flex-shrink-0">Download all</a> -->
        </div>
        <a ref="download" v-show="false" :href="downloadUrl" :download="downloadfilename"></a>
        <div class="hidden lg:grid grid-cols-2 lg:grid-cols-3 gap-2">
          <div v-for="pic in photos" :key="pic">
            <div class="w-full h-48 lg:h-56 cursor-pointer">
              <img class="w-full h-full object-cover"
                :src="pic"/>
            </div>
            <a :href="pic" @click.prevent="downloadItem(pic)" title="ImageName" class="text-pink-500 font-semibold">{{downloadText}}</a>
            <!-- <p class="text-gray-700 text-sm">{{image.width+' X '+image.height}}</p> -->
          </div>
        </div>
        <div class="block md:hidden">
          <carousel :autoplay="true" :centerMode="true" :per-page="1" :touchDrag="true" :mouse-drag="true">
            <slide v-for="pic in photos" :key="pic" >
              <div class="w-full h-48 lg:h-56 cursor-pointer">
                <img class="w-full h-full object-cover"
                    :src="pic"/>
              </div>
              <a :href="pic" @click.prevent="downloadItem(pic)" title="ImageName" class="text-pink-500 font-semibold">{{downloadText}}</a>
              <!-- <p class="text-gray-700 text-sm">{{image.width+' X '+image.height}}</p> -->
            </slide>
            </carousel>
        </div>
      </div>
      <!-- Videos-->
      <div v-if="youtubeLinks.length">
        <h4 class="guest-title">Videos</h4>
        <div class="hidden lg:grid grid-cols-1 lg:grid-cols-2 gap-8 ">
          <Video
            :video="video" v-for="(video, index) in youtubeLinks" :key="index"/>
        </div>
      <no-ssr>
        <flickity class="block lg:hidden" ref="flickity" :options="flickityOptions">
          <div v-for="(video, index) in youtubeLinks" :key="index" class="carousel-cell">
            <Video :video="video"/>
          </div>
        </flickity>
      </no-ssr>
      </div>
      <!-- Articles-->
      <div v-if="articles.length">
        <h4 class="guest-title">Articles</h4>
        <div class="hidden lg:grid grid-cols-1 lg:grid-cols-2 gap-8 ">
          <Article
            :article="article" v-for="(article, index) in articles" :key="index"/>
        </div>
        <no-ssr>
        <flickity class="block lg:hidden" ref="flickity" :options="flickityOptions">
          <div v-for="(article, index) in articles" :key="index" class="carousel-cell">
            <Article :article="article"/>
          </div>
        </flickity>
      </no-ssr>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import Podcast from "@/components/profile/links/Podcast";
import Article from "@/components/profile/links/Article";
import Video from "@/components/profile/links/Video";
import { isEmpty } from 'lodash';
import api from '@/api';


export default {
  name: 'Links',
  props: {
    links: Object,
    photos: [Object, Array],
    guestColor: String
  },
  components: {Podcast, Article, Video,Carousel, Slide},
  data() {
    return {
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        cellAlign: 'left',
      },
      image:{
        size:'',
        height:'',
        width:''
      },
      imageLoaded:false,
      downloadfilename: '',
      downloadUrl: '',
      downloadText: 'Download'
    }
  },

  methods: {
    whenImgLoaded(){
      //console.log(pic)
         this.imgLoaded = true;
         //this.setSize(pic)
   },
    isEmpty(object) {
      return isEmpty(object)
    },
    downloadItem(url) {
      this.downloadText = 'Working...'
      api.get(`download/image/to-string?url=${url}`)
        .then(response => {
          this.beginDownload(response.data.base64, response.data.ext);
          this.downloadText = 'Download'
        }).catch((error) => {
          if (error.response) {
            return this.$toast.error(error.response.data.message)
          }

          this.$toast.error('Unable to complete download.')
        });
    },
    beginDownload(imgUrl, ext) {
      let timestamp = new Date().getTime()
      let name = imgUrl.substring(22, 30) + timestamp + '.' + ext
      this.downloadUrl = imgUrl
      this.downloadfilename = name
      setTimeout(() => {
        this.$refs.download.click()
      }, 100)
    }
    // setSize(pic){
    // console.log("in")
    // let img = new Image();
    // img.src = pic;
    // this.image.width = img.width;
    // this.image.height = img.height;
    // }
  },
  computed: {
    podcasts() {
      return this.links ? this.links.podcasts_parsed : []
    },

    youtubeLinks() {
      return this.links ? this.links.videos_parsed : [];
    },

    articles() {
      return this.links ? this.links.articles_parsed : [];
    },
  },
}
</script>

<style scoped>
.carousel-cell {
  width: 80%;
  @apply mr-5 ;
}
</style>
